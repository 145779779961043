import type { ApolloError } from '@apollo/client';
import { buildComponentAndLocale } from '@aurora/shared-apollo/helpers/ApolloComponentCacheHelper';
import useCachedAsset from './assets/useCachedAsset';
import AppContext from './context/AppContext/AppContext';
import useCachedComponentBundle from './useCachedComponentBundle';
import useLocale from './useLocale';
import type { CachedComponent } from '@aurora/shared-types/components';
import { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';

const log = getLog(module);

export interface ComponentResponse {
  /**
   * The loading state
   */
  loading: boolean;

  /**
   * The component
   */
  data: CachedComponent;

  /**
   * Is this a local override of the component?
   */
  localOverride: boolean;

  /**
   * The Apollo error, if any
   */
  error?: ApolloError;
}

/**
 * Attempts to fetch a cached component, or fetches and caches if not found.
 * @param componentId The component ID
 * @param latest If true, always fetch the latest version
 * @returns the component
 */
export default function useCachedComponent(
  componentId: string,
  latest: boolean
): ComponentResponse {
  const { componentsLastModified } = useContext(AppContext);
  const locale = useLocale();
  const componentsLastModifiedAdjusted = latest ? Date.now().toString() : componentsLastModified;
  const cachedBundle = useCachedComponentBundle(
    componentId,
    locale,
    componentsLastModifiedAdjusted
  );
  const skip = !!cachedBundle || !componentId;
  const { loading, data, error } = useCachedAsset(
    buildComponentAndLocale(componentId, locale),
    CachedAssetType.COMPONENT,
    componentsLastModified,
    null,
    null,
    skip
  );
  if (error) {
    log.error(error, 'Error loading component with id: %s and locale %s', componentId, locale);
  }
  if (cachedBundle) {
    return { loading: false, data: cachedBundle.value, localOverride: cachedBundle.localOverride };
  } else {
    return {
      loading,
      error,
      data: data?.cachedAsset?.value,
      localOverride: data?.cachedAsset?.localOverride
    };
  }
}
