import { buildPrefixedComponentBundleId } from '@aurora/shared-apollo/helpers/ApolloComponentCacheHelper';
import type { CachedAssetFragment } from '@aurora/shared-generated/types/graphql-types';
import useCachedFragment from './useCachedFragment';

/**
 * Hook for fetching a component from the Apollo cache
 * @param componentId The ID of the component
 * @param locale component texts locale
 * @param lastModified Last modified timestamp
 * @returns Cached component, or null
 */
export default function useCachedComponentBundle(
  componentId: string,
  locale: string,
  lastModified: string
): CachedAssetFragment {
  const bundleId = componentId
    ? buildPrefixedComponentBundleId(componentId, locale, lastModified)
    : null;
  return useCachedFragment(bundleId);
}
